//提现管理列表
export const withdrawManageCols = [
  {
    prop: 'nickName',
    label: '分销商',
    minWidth: '200'
  },
  {
    prop: 'amount',
    label: '提现金额',
    minWidth: '100'
  },
  {
    prop: 'withdrawalFee',
    label: '提现手续费',
    minWidth: '100'
  },
  {
    prop: 'realAmount',
    label: '实际提现金额',
    minWidth: '100'
  },
  {
    prop: 'remainingAmount',
    label: '当前剩余金额',
    minWidth: '100'
  },
  {
    prop: 'withdrawState',
    label: '审核状态',
    minWidth: '100',
    slotName: 'withdrawSlot'
  },
  {
    prop: 'applyTime',
    label: '申请时间',
    minWidth: '200',
    slotName: 'applyTimeSlot'
  }
]
